// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-csv-format-js": () => import("./../../../src/pages/csv-format.js" /* webpackChunkName: "component---src-pages-csv-format-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-js": () => import("./../../../src/pages/install.js" /* webpackChunkName: "component---src-pages-install-js" */),
  "component---src-pages-instructions-js": () => import("./../../../src/pages/instructions.js" /* webpackChunkName: "component---src-pages-instructions-js" */),
  "component---src-pages-licence-js": () => import("./../../../src/pages/licence.js" /* webpackChunkName: "component---src-pages-licence-js" */),
  "component---src-pages-pw-forget-js": () => import("./../../../src/pages/pw-forget.js" /* webpackChunkName: "component---src-pages-pw-forget-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */)
}

